import "./homeMain.css";
import TopImage from "../../assets/images/topImage.png";
import RightImage from "../../assets/images/rightImage.png";
import AiSummit from "../../assets/images/aiSummit.png";
import Calender from "../../assets/images/calender.png";
import Place from "../../assets/images/palce.png";
import Google from "../../assets/images/google.png";

const HomeMain = () => {
  return (
    <>
      <div className="home_main_container">
        <div className="top_corner_image_container">
          <img src={TopImage} alt="image" className="top_corner_image" />
        </div>

        <div className="top_section_text_container">
          <div className="kerala_text">
            KERALA'S <span className="large_text">LARGEST</span>
          </div>

          <div className="ai_image_conatiner">
            <img src={AiSummit} alt="ai_summit_image" className="ai_image" />
          </div>

          <div className="kerala_text">
            FOR STUDENTS BY <span className="large_text">ACCELERATE X</span>
          </div>

          <div className="venue_container">
            <div className="date">
              <div className="date_image_container">
                <img src={Calender} alt="date_image" className="date_image" />
              </div>
              <div className="date_text">19 OCTOBER 2024</div>
            </div>
            <div className="venue">
              <div className="venue_image_container">
                <img src={Place} alt="venue_image" className="date_image" />
              </div>
              <div className="venue_text_container">
                <div className="date_text">MAR ATHANASIUS COLLEGE OF</div>
                <div className="date_text">ENGINEERING, KOTHAMANGALAM</div>
              </div>
            </div>
          </div>

          <div className="eco_container">
            <div className="eco_text">ECOSYSTEM PARTNER</div>
            <div className="google_image_container">
              <img src={Google} alt="google_image" className="google_image" />
            </div>
          </div>
        </div>

        <div className="right_image_container">
          <img src={RightImage} alt="image" className="right_image" />
        </div>
      </div>
    </>
  );
};

export default HomeMain;
