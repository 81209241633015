import React from 'react';
import { useNavigate } from "react-router-dom";

import contact from '../assets/contact.png';
import Navbar from '../components/Navbar';
import earth from '../assets/earth.png';
import Footer from '../components/Footer';

const RegisterContact = () => {

    const navigate = useNavigate();

    return (
        <>
            <Navbar />
            <section className='register-contact-page'>
                <img className='earth-img' src={earth} alt='' />
                <div className="register-contact-mobile">
                    <img src={contact} className='register-contact-page-img' alt='' />
                    <div className='register-contact-page-text-container'>
                        <div className='register-contact-page-text-mobile'>
                            <div>
                                Kindly <span style={{ fontWeight: 700, color: "#25D366" }}>Whatsapp</span> in
                            </div>
                            <div>
                                +91 7736 0911 14
                            </div>
                            <div style={{ fontSize: '20px', fontWeight: '500' }}>
                                for knowing the payment details.
                            </div>
                        </div>
                    </div>
                    <button className='ok-button'
                        style={{ width: "20%", height: "3rem", margin: "30px auto" }}
                        onClick={() => navigate("/")}>
                        OK
                    </button>
                </div>
                <div className="register-contact-desktop">
                    <img src={contact} className='register-contact-page-img' alt='' />
                    <div className='register-contact-page-text-container'>
                        <div className='register-contact-page-text-desktop'>
                            <div>
                                Kindly <span style={{ fontWeight: 700, color: "#25D366" }}>Whatsapp</span> in
                            </div>
                            <div>
                                +91 7736 0911 14
                            </div>
                            <div style={{ fontSize: '20px', fontWeight: '500' }}>
                                for knowing the payment details.
                            </div>
                            <button className='ok-button'
                                onClick={() => navigate("/")}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default RegisterContact;
