import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faStar } from "@fortawesome/free-regular-svg-icons";

import gift from "../assets/gift.png";
import earthGirl from "../assets/earthGirl.png";

import { institutes } from "../constants/institutes";
import { locations } from "../constants/location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
const Register = () => {
  const navigate = useNavigate();

  const [institution, setInstitution] = useState(institutes);
  const [institutionFilter, setInstitutionFilter] = useState(institutes);
  const [location, setLocation] = useState("All");
  const [type, setType] = useState("Online");

  useEffect(() => {
    setInstitution(institutes);
    handleFilter("All", "Online");
  }, []);

  const handleFilter = (item, classType) => {
    setLocation(item);
    if (item === "All") {
      setInstitutionFilter(institutes);
      handleTypeFilter(classType, institutes);
    } else {
      const results = institutes.filter((obj) => {
        return obj.locations.includes(item);
      });
      setInstitutionFilter(results);
      handleTypeFilter(classType, results);
    }
  };

  const handleTypeFilter = (classType, institutionData) => {
    setType(classType);
    var _institution = JSON.parse(JSON.stringify(institutionData));
    _institution.filter((obj) => {
      const val = obj.batches.filter((item) => {
        return item.type === classType;
      });

      obj.batches = val;
    });

    setInstitution(_institution.filter((inst) => inst.batches.length > 0));
  };

  return (
    <section className="register-screen">
      <Navbar />
      <img className='earth-img' src={earthGirl} alt='' />
      <div className="title">
        Get the best IELTS Coaching from
        <br />
        <span style={{ fontWeight: 500 }}>
          these Handpicked Institutes with
        </span>
        <br />
        Special fee Discounts.
      </div>

      <div className="title-mobile">
        Get the best
        <br />
        IELTS Coaching
        <br />
        from these
        <br />
        Handpicked Institutes
        <br />
        with fee Discounts.
      </div>

      <div className="divider"></div>

      <div className="type-filter-container">
        <span
          className={
            type === "Online" ? "type-filter type-filter-active" : "type-filter"
          }
          onClick={() => handleTypeFilter("Online", institutes)}
        >
          Online
        </span>
        <span
          className={
            type === "Offline"
              ? "type-filter type-filter-active"
              : "type-filter"
          }
          onClick={() => handleTypeFilter("Offline", institutionFilter)}
        >
          Offline
        </span>
      </div>

      {type === "Offline" ? (
        <ul className="location-filter ">
          {locations.map((item) => (
            <button
              // className="location-item type-filter-active"
              className={
                location === item.location
                  ? "location-item type-filter-active"
                  : "location-item"
              }
              onClick={() => handleFilter(item.location, type)}
            >
              {item.location}
            </button>
          ))}
        </ul>
      ) : null}

      <div className="partners-container">
        {institution?.map((institute) => (
          <div className="partner" key={institute.name}>
            <div className="title-card">
              <div className="title-container">
                <div className="partner-logo">
                  <img
                    src={institute.logo}
                    alt="institution-logo"
                    className="institution-logo"
                  />
                </div>
                <span className="title-text">{institute.name}</span>
              </div>
              <div className="features">
                {institute.features.map((feature) => (
                  <div className="feature-text" key={Math.random()}>
                    <FontAwesomeIcon icon={faStar} /> {feature}
                  </div>
                ))}
              </div>
            </div>
            {institute.batches.map((batch) => (
              <div
                className={
                  // institution === institute.name &&
                  //     institutionBatch === batch.name
                  //     ? "batch batch-selected-" :
                  "batch"
                }
                key={Math.random()}
              >
                <div className="batch-name-container">
                  <span className="batch-name-text">{batch.name}</span>
                  <span className="class-type-text">{batch.type}</span>
                </div>
                <div className="batch-details">
                  <div
                    className="fee-container"
                    style={{
                      background: "#F2F3F7",
                      color: "#1F1E41",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <span className="fee-text">Usual Fee</span>
                    </div>
                    <div style={{ width: "40%" }}>
                      <span className="fee-amount-text">
                        ₹ {batch.usualFee}/-
                      </span>
                    </div>
                  </div>
                  <div
                    className="fee-container"
                    style={{
                      background: "#9F72FF",
                      color: "#FFF",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <span className="fee-text">Via Us</span>
                    </div>
                    <div style={{ width: "40%" }}>
                      <span className="fee-amount-text">₹ {batch.viaUs}/-</span>
                    </div>
                  </div>
                </div>
                <div className="location-details">
                  <div className="offer">
                    <img src={gift} alt="gift" className="gift-img" />
                    <span className="offer-text">
                      <span className="offer-text-bold">₹ {batch.off}</span>
                      &nbsp;OFF
                    </span>
                  </div>
                  <button
                    className={
                      // institution === institute.name &&
                      //     institutionBatch === batch.name
                      //     ? "select-button select-button-selected-":
                      "select-button"
                    }
                    onClick={() => {
                      navigate("/register-form", {
                        state: {
                          institute: institute,
                          batch: batch,
                        },
                      });
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <Footer />
    </section>
  );
};

export default Register;
