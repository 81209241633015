import React from 'react';
import beingAbroadLogo from "../assets/being-abroad-logo.png";

const Navbar = ({ color }) => {
    return (
        <nav className="nav">
            <a href='/' className='home-logo-link'><img src={beingAbroadLogo} alt="being-abroad-logo" className="being-abroad-logo" /></a>
            <div className="navigation-pages">
                <span className="navigation-page">
                    <a href='/' className="navigation-link" style={{color: color}}>Home</a>
                </span>
                <span className="navigation-page">
                    <a href='/about-us' className="navigation-link" style={{color: color}}>About</a>
                </span>
                <span className="navigation-page">
                    <a href='/contact-us' className="navigation-link" style={{color: color}}>Contact</a>
                </span>
            </div>
        </nav>
    )
}

export default Navbar;
