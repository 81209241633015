import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// import eventLogo from "../assets/event-logo.webp";
import eventStar from "../assets/event-star.webp";
import eventCooperation from "../assets/event-co-operation.webp";
import eventStudent from "../assets/event-student.webp";
import eventPay from "../assets/event-pay.webp";
import eventOffer from "../assets/event-offer.webp"
import eventCoupon from "../assets/event-coupon.webp";
import eventDiscount from "../assets/event-discount.png";
import mixpanel from 'mixpanel-browser';
// import eventFlight from "../assets/event-flight.webp";
import homeVector from "../assets/home-vector-desktop.webp";
import cashVector from "../assets/cash-vector.webp";
import diamondVector from "../assets/diamond-vector.webp";
import lsoonVector from "../assets/app-launch-vector-desktop.webp";
import spiralVector from "../assets/spiral-vector.webp";
import giftVector from "../assets/gift-vector.webp";
import contractVector from "../assets/contract-vector.webp";
import customerServiceVector from "../assets/customer-service-vector.webp";
import lsoonVectorMobile from "../assets/app-launch-vector-mobile.webp";

import heroImg from '../assets/hero-app.webp'

import playStore from '../assets/hero-google-play.svg';
import appStore from '../assets/hero-app-store.svg';

const benefits = [{
    image: eventCooperation,
    text: <span className='event-benefit-text'>Free 1 Month<br />IELTS Coaching<br />For all</span>
},
{
    image: eventStudent,
    text: <span className='event-benefit-text'>Interaction with<br />students abroad</span>
},
{
    image: eventPay,
    text: <span className='event-benefit-text'>Edu-Loan<br />Support</span>
},
{
    image: eventOffer,
    text: <span className='event-benefit-text'>Shopping<br />Coupons</span>
},
{
    image: eventCoupon,
    text: <span className='event-benefit-text'>IELTS Discount<br />Coupons</span>
},
{
    image: eventDiscount,
    text: <span className='event-benefit-text'>Exclusive Dicount<br />for Learning<br />Courses.</span>
},
    // {
    //     image: eventFlight,
    //     text: <span className='event-benefit-text'>Free<br />Air Ticket</span>
    // },
]

const Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        mixpanel.track('Website - Home')
    }, [])

    return (
        <section className="home-screen">
            <Navbar />

            <div className="hero">
                <div className="hero-left">
                    <span className="hero-text">
                        Let's Revolutionize<br />
                        Studying Abroad<br />
                        with Being Abroad App
                    </span>
                    <div className="blur-text-container">
                        <span className="blur-text">
                            India’s 1st Study Abroad Aggregator Platform
                        </span>
                    </div>
                    <div className="store-links">
                        <a href='https://play.google.com/store/apps/details?id=com.beingabroad'>
                            <img className='store-link-img' alt='google-play-store' src={playStore} />
                        </a>
                        <a href='https://apps.apple.com/us/app/being-abroad/id6470450367'>
                            <img className='store-link-img' alt='apple-app-store' src={appStore} />
                        </a>
                    </div>
                </div>
                <div className="hero-right">
                    <img className='hero-image' src={heroImg} alt="hero-image" />
                </div>
            </div>

            {/* <img src={homeVector} alt="home-vector" className="home-vector" />
            <img src={homeVector} alt="home-vector" className="home-vector-mobile" />

            <div className="home-title">
                <span className="home-title-text">
                    <span style={{
                        color: "#7B4CFF",
                        fontWeight: 300,
                    }}>Get the</span> <br />
                    <span style={{
                        color: "#FFF",
                        fontWeight: 300,
                    }}>Best IELTS Coaching from<br />the </span>
                    <span style={{
                        color: "#FFF",
                        fontWeight: 700,
                    }}>Top Institutes in Kerala.</span><br />
                </span>
            </div>
            <div className="home-title-mobile">
                <span className="home-title-text">
                    <span style={{
                        color: "#7B4CFF",
                        fontWeight: 300,
                    }}>Get the</span> <br />
                    <span style={{
                        color: "#FFF",
                        fontWeight: 300,
                    }}>Best IELTS<br />Coaching<br />from the</span>
                    <span style={{
                        color: "#FFF",
                        fontWeight: 700,
                    }}>Top<br />Institutes<br />in Kerala.</span><br />
                </span>
            </div>
            <div className="select-button-container">
                <div className="button-container">
                    <div>Choose from<br />the best Institutes at<br />Discounted rates</div>
                    <button className="discount-btn" onClick={() => navigate('/register')}>
                        Select Now
                    </button>
                </div>
                <div className="cash-vector-container">
                    <img src={cashVector} alt='' className='cash-vector' />
                </div>
            </div>
            <div className="handpick">
                <span className="handpick-text">Being Abroad has handpicked<br /></span>
                <span className="handpick-text">the best Institutes after collecting reviews from over<br /></span>
                <span style={{ color: "#7B4CFF" }} className="handpick-text">4000+ of our community Members.</span>
            </div>
            <div className="handpick-mobile">
                <span className="handpick-text">Being Abroad has handpicked<br /></span>
                <span className="handpick-text">the best Institutes after<br /></span>
                <span className="handpick-text">collecting reviews from over<br /></span>
                <span style={{ color: "#7B4CFF" }} className="handpick-text">4000+ of our community<br /></span>
                <span style={{ color: "#7B4CFF" }} className="handpick-text">Members.</span>
            </div>
            <div className="benefits">
                <div className='benefit-title'>
                    <span>Benefits of taking Admission via us </span><img src={diamondVector} alt="" style={{ width: "40px" }} />
                </div>
                <div className='benefit-title-mobile'>
                    <span>Benefits of taking<br />Admission via us </span><img src={diamondVector} alt="" style={{ width: "50px", marginLeft: "20px" }} />
                </div>

                <div className="benefit-container">
                    <div className="benefit benefit-small-tile">
                        <div className="benefit-text-container">
                            <span className="benefit-light-text">
                                Exclusive<br />Fee Discount<br />
                            </span>
                            <span className="benefit-bold-text">
                                Upto<br />Rs.1500/-
                            </span>
                        </div>
                        <img src={giftVector} alt='' className='benefit-img' />
                    </div>
                    <div className="benefit benefit-small-tile">
                        <div className="benefit-text-container">
                            <span className="benefit-light-text">
                                Free<br />Assistance over<br />
                            </span>
                            <span className="benefit-bold-text">
                                Admission<br />Procedures.
                            </span>
                        </div>
                        <img src={contractVector} alt='' className='benefit-img' />
                    </div>
                    <div className="benefit benefit-large-tile">
                        <div className="benefit-text-container">
                            <span className="benefit-light-text">
                                Free Mentor<br />Call assistance on<br />
                            </span>
                            <span className="benefit-bold-text">
                                Clearing your<br />Study abroad Doubts.
                            </span>
                        </div>
                        <img src={customerServiceVector} alt='' className='benefit-img' />
                    </div>
                </div>

            </div>
            <div className="spiral">
                <img src={spiralVector} alt='' className='spiral-img' />
                <div className="spiral-text">
                    <span className="spiral-bold-text">
                        If you're still<br />confused about<br />
                    </span>
                    selecting the best institute for your IELTS coaching,<br />
                    then click the <span className='yellow-text'>“Select now”</span> button above now.
                </div>
            </div> */}
            {/* <div className="app-launch">
                <img src={lsoonVector} className='lsoon-img' />
                <img src={lsoonVectorMobile} className='lsoon-img-mobile' />
            </div> */}

            <Footer />
        </section >
    )
}

export default Home;
