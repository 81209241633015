export const locations = [
  {
    location: "All",
  },
  // {
  //   location: "Kasargod",
  // },
  // {
  //   location: "Kannur",
  // },
  {
    location: "Calicut",
  },
  // {
  //   location: "Wayanad",
  // },
  // {
  //   location: "Malappuram",
  // },
  // {
  //   location: "Palakkad",
  // },
  // {
  //   location: "Thrissur",
  // },
  {
    location: "Ernakulam",
  },
  // {
  //   location: "Alappuzha",
  // },
  // {
  //   location: "Idukki",
  // },
  {
    location: "Kottayam",
  },
  {
    location: "Pathanamthitta",
  },
  // {
  //   location: "Kollam",
  // },
  {
    location: "Trivandrum",
  },
  {
    location: "Manjeri",
  },
  {
    location: "Pala",
  },
];
