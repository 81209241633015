import React from 'react';
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";

import './index.scss';

import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Register from './pages/Register';
import RegisterForm from './pages/RegisterForm';
import RegisterContact from './pages/RegisterContact';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AiSummitHomeScreen from './pages/aiSummitHomeScreen/AiSummitHomeScreen';

mixpanel.init("c3930f26068f4492c298a419e5378ee9", {
  persistence: "localStorage"
});

const TRACKING_ID = "G-TB42FTBG4X";
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path='/' element={<Home />} exact />
      <Route path='/contact-us' element={<Contact />} exact />
      <Route path='/register' element={<Register />} exact /> 
      <Route path='/about-us' element={<About />} exact />
      <Route path='/register-form' element={<RegisterForm />} exact />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} exact />
      <Route path='/register-contact' element={<RegisterContact />} exact />
      <Route path='/terms-and-conditions' element={<TermsAndConditions />} exact />
      <Route path='/aisummit' element={<AiSummitHomeScreen />} exact />
    </Routes>
  </Router>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
