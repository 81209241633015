import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import mixpanel from "mixpanel-browser";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    fetchTermsAndConditions();
    mixpanel.track('Website - Terms & Conditions')
  }, []);

  const fetchTermsAndConditions = async () => {
    const { data } = await axios.get(
      "https://www.thebeingabroad.com/api/v1/auth/termsAndConditions",
      {
        headers: {
          withCredentials: false,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if(data) {
      setTermsAndConditions(data.termsAndConditions);
    }
    // .then((res) => {
    //   setTermsAndConditions(res.data?.termsAndConditions);
    // });
  };

  return (
    <>
      <Navbar />
      <div style={{ margin: "3rem 1rem 3rem 1rem" }}>
        {/* <span dangerouslySetInnerHTML={{ __html: termsAndConditions }} /> */}
        {termsAndConditions?.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
