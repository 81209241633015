import "./contact.css";
import Item1 from "../../assets/images/item1.png";
import Item2 from "../../assets/images/item2.png";
import Item3 from "../../assets/images/item3.png";
import Item4 from "../../assets/images/item4.png";
import Item5 from "../../assets/images/item5.png";

const Contact = () => {
  return (
    <>
      <div className="contact_container contact_top_small_container">
        <div className="contact_item_container">
          <img src={Item1} alt="contact_img" className="contact_item_img" />
          <div className="contact_item_text">19 October 2024</div>
        </div>

        <div className="contact_item_container">
          <img src={Item2} alt="contact_img" className="contact_item_img" />
          <div className="contact_item_text">09:00 AM - 05:00 PM</div>
        </div>

        <div className="contact_item_container">
          <img src={Item3} alt="contact_img" className="contact_item_img" />
          <div className="contact_item_text">
            Mar Athanasius College of
            <br />
            Engineering, Kothamangalam
          </div>
        </div>
      </div>

      <div className="contact_container contact_small_container" style={{ marginTop: "30px" }}>
        <div className="contact_item_container">
          <img src={Item4} alt="contact_img" className="contact_item_img" />
          <div className="contact_item_text">
            For more details
            <br />
            <strong>+91 77367 29997</strong>
          </div>
        </div>

        <div className="contact_item_container">
          <img src={Item5} alt="contact_img" className="contact_item_img" />
          <div className="contact_item_text">
            <strong>Support@thebeingabroad.com</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
