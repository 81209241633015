import angloverseLogo from "../assets/angloverse.png";
import letsUpdateLogo from "../assets/let's-update.png";
import dishaLogo from "../assets/disha.png";
import cosmoLogo from "../assets/cosmo.svg";
import graceLogo from "../assets/grace.png";
import ieltsMasterLogo from "../assets/ieltsMasters.png";
import venanzaLogo from "../assets/venanza.png";
import jubileeLogo from "../assets/jubilee.png";
import kkLogo from "../assets/kk.png";
import ellsLogo from "../assets/ells.png";
import zodEduLogo from "../assets/zodEdu.png";

export const institutes = [
  {
    name: "Angloverse",
    logo: angloverseLogo,
    features: [
      "3 Students/ Class.",
      "IDP Certified Tutors with 8 Band.",
      "Choose class timings from 6 AM- 12 AM.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 7500,
        off: 500,
        type: "Online",
        timings: [
          "7:00 AM - 08:00 AM",
          "12:00 PM - 1:00 PM",
          "3:00 PM - 5:00 PM",
          "9:00 PM - 11:00 PM",
        ],
      },
      {
        name: "2 Month Batch",
        usualFee: 12000,
        viaUs: 10999,
        off: 1001,
        type: "Online",
        timings: [
          "7:00 AM - 08:00 AM",
          "12:00 PM - 1:00 PM",
          "3:00 PM - 5:00 PM",
          "9:00 PM - 11:00 PM",
        ],
      },
    ],
    locations: [],
  },
  {
    name: "Lets's Update Language Academy",
    logo: letsUpdateLogo,
    features: [
      "Grammar Classes every day",
      "Individual Training for all",
      "Free Hard copy study materials",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 6500,
        viaUs: 6250,
        off: 250,
        type: "Online",
        timings: ["10:00 AM - 01:00 PM", "2:00 PM - 5:00 PM"],
      },
      {
        name: "1 Month Batch",
        usualFee: 5900,
        viaUs: 5650,
        off: 250,
        type: "Offline",
        timings: ["09:30 AM - 04:30 PM"],
      },
      {
        name: "Unlimited Training",
        usualFee: 15500,
        viaUs: 15000,
        off: 500,
        type: "Online",
        timings: "You can choose from 07:30 AM - 07:30 PM",
      },
      {
        name: "Unlimited Training",
        usualFee: 11800,
        viaUs: 11300,
        off: 500,
        type: "Offline",
        timings: "You can choose from 07:30 AM - 07:30 PM",
      },
    ],
    locations: ["Ernakulam"],
  },
  {
    name: "ELLS Academy",
    logo: ellsLogo,
    features: [
      "Personalised Training.",
      "Balanced Focus on All Modules.",
      "Flexible Learning Experience.",
      "24 * 7 Slots with Individual Care."
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 7500,
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 04:00 AM"],
      },
      {
        name: "2 Month Batch",
        usualFee: 14000,
        viaUs: 13500,
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 04:00 AM"],
      },
      {
        name: "1 Month Individual Batch",
        usualFee: 8500,
        viaUs: 8000,
        off: 500,
        type: "Online",
        timings: "24 * 7 Individual Slots Available.",
      },
      {
        name: "2 Month Batch",
        usualFee: 15000,
        viaUs: 14500,
        off: 500,
        type: "Online",
        timings: "24 * 7 Individual Slots Available.",
      },
    ],
    locations: ["Pala", "Kottayam"],
  },
  {
    name: "Disha Academy for IELTS & OET",
    logo: dishaLogo,
    features: [
      "Most Student-Centric Institute.",
      "Flexible Timing.",
      "The Highest winning proportion.",
      "Individual Attention.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 8500,
        viaUs: 8000,
        off: 500,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "1 Month Batch",
        usualFee: 8500,
        viaUs: 8000,
        off: 500,
        type: "Offline",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "2 Month Batch IELTS",
        usualFee: 13000,
        viaUs: 11999,
        off: 1000,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "2 Month Batch IELTS",
        usualFee: 13000,
        viaUs: 11999,
        off: 1000,
        type: "Offline",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
    ],
    locations: ["Ernakulam"],
  },
  {
    name: "Cosmo Centre for Education & Training",
    logo: cosmoLogo,
    features: [
      "Pattern based Learning.",
      "Method based Speaking.",
      "45,000+ students trained.",
      "Rs.12,000+ Additional Benefits.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 7500,
        viaUs: 6999,
        off: 501,
        type: "Online",
        timings: [
          "07:00 AM - 09:00 PM",
          "10:00 PM - 12:00 PM",
          "04:30 PM - 6:30 PM",
          "09:00 PM - 11:00 PM",
        ],
      },
      {
        name: "1 Month Batch",
        usualFee: 7500,
        viaUs: 6999,
        off: 501,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
      {
        name: "2 Month Batch",
        usualFee: 10500,
        viaUs: 9500,
        off: 1000,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
    ],
    locations: ["Ernakulam", "Kottayam", "Trivandrum"],
  },
  {
    name: "Jubilee Training Center",
    logo: jubileeLogo,
    features: [
      "Systematic Guidance By Qualified Staffs.",
      "High Training Excellence.",
      "Develop Good Communication Skills.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 8000,
        off: 0,
        type: "Online",
        timings: [
          "07:00 AM - 09:00 PM",
          "10:00 PM - 12:00 PM",
          "04:30 PM - 6:30 PM",
          "09:00 PM - 11:00 PM",
        ],
      },
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 8000,
        off: 0,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
      {
        name: "2 Month Batch",
        usualFee: 12000,
        viaUs: 12000,
        off: 0,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
    ],
    locations: ["Kottayam"],
  },
  {
    name: "Venanza International",
    logo: venanzaLogo,
    features: [
      "Expert Faculties for German, French, IELTS, OET.",
      "Unlimited materials for Study and writing corrections.",
      "Special Grammer classes.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 10000,
        viaUs: 10000,
        off: 0,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "1 Month Batch",
        usualFee: 10000,
        viaUs: 10000,
        off: 0,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
      {
        name: "15 Days Crash Course",
        usualFee: 7000,
        viaUs: 7000,
        off: 0,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "15 Days Crash Course",
        usualFee: 7000,
        viaUs: 7000,
        off: 0,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
    ],
    locations: ["Pathanamthitta", "Kottayam"],
  },
  {
    name: "Grace Academy",
    logo: graceLogo,
    features: [
      "professional IELTS Training.",
      "Mock Tests.",
      "Exceptional Faculty.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: "8000 + GST",
        viaUs: "7500 + GST",
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
      {
        name: "2 Month Batch",
        usualFee: "11500 + GST",
        viaUs: "11000 + GST",
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 12:00 PM", "10:00 AM - 03:00 PM"],
      },
      {
        name: "15 Days Crash Course",
        usualFee: 6000,
        viaUs: 5500,
        off: 500,
        type: "Online",
        timings: [
          "07:00 AM - 09:00 PM",
          "10:00 PM - 12:00 PM",
          "04:30 PM - 6:30 PM",
          "09:00 PM - 11:00 PM",
        ],
      },
    ],
    locations: ["Aluva", "Pala"],
  },
  {
    name: "Creative Learning Centre",
    logo: ieltsMasterLogo,
    features: [
      "Personal attention and advanced study materials.",
      "Free Trial Classes.",
      "Regular Speaking Practices.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 7200,
        viaUs: 7200,
        off: 0,
        type: "Online",
        timings: [
          "07:00 AM - 08:00 AM",
          "10:00 AM - 11:00 AM",
          "01:00 AM - 02:00 PM",
          "09:00 PM - 10:00 PM",
        ],
      },
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 7500,
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 04:00 PM"],
      },
      {
        name: "14 Days Crash Course",
        usualFee: 2800,
        viaUs: 2800,
        off: 0,
        type: "Online",
        timings: ["08:00 PM - 09:00 PM"],
      },
    ],
    locations: ["Calicut", "Manjeri"],
  },
  {
    name: "IELTS Master.com",
    logo: ieltsMasterLogo,
    features: [
      "IELTS Master Student Portal.",
      "Personal Mentor Support.",
      "Practices and Detailed Feedback.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 7200,
        viaUs: 7200,
        off: 0,
        type: "Online",
        timings: [
          "07:00 AM - 08:00 AM",
          "10:00 AM - 11:00 AM",
          "01:00 AM - 02:00 PM",
          "09:00 PM - 10:00 PM",
        ],
      },
      {
        name: "1 Month Batch",
        usualFee: 8000,
        viaUs: 7500,
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 04:00 PM"],
      },
      {
        name: "14 Days Crash Course",
        usualFee: 2800,
        viaUs: 2800,
        off: 0,
        type: "Online",
        timings: ["08:00 PM - 09:00 PM"],
      },
    ],
    locations: ["Calicut", "Manjeri"],
  },
  {
    name: "Kanjirakadans Academy",
    logo: kkLogo,
    features: [
      "Individualized Guidance.",
      "Complimentary Free demo class.",
      "Unique Coaching.",
    ],
    batches: [
      {
        name: "1 Month Batch",
        usualFee: 6999,
        viaUs: 6499,
        off: 500,
        type: "Offline",
        timings: ["10:00 AM - 04:00 AM"],
      },
      {
        name: "2 Week Batch",
        usualFee: 4999,
        viaUs: 4999,
        off: 0,
        type: "Offline",
        timings: ["10:00 AM - 04:00 PM"],
      },
      {
        name: "Learners Pack",
        usualFee: 8999,
        viaUs: 8499,
        off: 500,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "Special Pack",
        usualFee: 11999,
        viaUs: 11499,
        off: 500,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
    ],
    locations: ["Kottayam"],
  },
  {
    name: "ZodEDU",
    logo: zodEduLogo,
    features: [
      "Diverse and Experienced Instructors.",
      "Tailored Curriculum.",
      "Interactive Learning Environment."
    ],
    batches: [
      {
        name: "1 Month Standard Batch",
        usualFee: 7000,
        viaUs: 6500,
        off: 500,
        type: "Offline",
        timings: ["09:00 AM - 01:00 AM", "02:00 AM - 05:00 AM"],
      },
      {
        name: "1 Month Premium Crash Course",
        usualFee: 6000,
        viaUs: 5500,
        off: 500,
        type: "Offline",
        timings: ["09:00 AM - 01:00 AM", "02:00 AM - 05:00 AM"],
      },
      {
        name: "1 Month Crash Course",
        usualFee: 8000,
        viaUs: 7500,
        off: 500,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
      {
        name: "2 Month Express Course",
        usualFee: 14000,
        viaUs: 13500,
        off: 500,
        type: "Online",
        timings: "You can choose time according to the availability after joining in the Institute.",
      },
    ],
    locations: ["Manjeri"],
  },
    // {
  //   name: "Universal Language studio",
  //   logo: universalLogo,
  //   features: [
  //     "Quality Training",
  //     "Daily Live Classes",
  //     "Free Study Materials",
  //     "AC classrooms with  practice labs.",
  //   ],
  //   batches: [
  //     {
  //       name: "1 Month Batch",
  //       usualFee: 5000,
  //       viaUs: 4000,
  //       off: 1000,
  //       type: "Online",
  //       timings: ["07:00 PM - 08:30 PM"],
  //     },
  //     {
  //       name: "1 Month Batch",
  //       usualFee: 5000,
  //       viaUs: 4000,
  //       off: 1000,
  //       type: "Offline",
  //       timings: ["10:00 AM - 01:00 PM", "2:00 PM - 5:00 PM"],
  //     },
  //     {
  //       name: "1 Month Individual class",
  //       usualFee: 10000,
  //       viaUs: 8500,
  //       off: 1500,
  //       type: "Online",
  //       timings: "You can choose convenient time for daily 1 hour class.",
  //     },
  //     {
  //       name: "1 Month Individual class",
  //       usualFee: 10000,
  //       viaUs: 8500,
  //       off: 1500,
  //       type: "Offline",
  //       timings: "You can choose convenient time for daily 1 hour class.",
  //     },
  //   ],
  //   locations: ["Ernakulam", "Trivandrum"],
  // },
    // {
  //     name: "GTech Gensmart",
  //     logo: ieltsMaster,
  //     features: [
  //         "IELTS Master Student Portal.",
  //         "Personal Mentor Support.",
  //         "Practices and Detailed Feedback."
  //     ],
  //     batches: [
  //         {
  //             name: "1 Month Batch",
  //             usualFee: 8000,
  //             viaUs: 7500,
  //             off: 500,
  //             type: "Offline",
  //             timings: ["09:00 AM - 01:00 AM", "01:00 PM - 05:00 PM"],
  //         },
  //         {
  //             name: "Unlimited Classes",
  //             usualFee: 10000,
  //             viaUs: 9500,
  //             off: 500,
  //             type: "Offline",
  //             timings: ["09:00 AM - 01:00 AM", "01:00 PM - 05:00 PM"],
  //         },
  //         {
  //             name: "Unlimited Classes",
  //             usualFee: 10000,
  //             viaUs: 9500,
  //             off: 500,
  //             type: "Online",
  //             timings: "You can choose time according to the availability after joining in the Institute.",
  //         },
  //     ],
  //     locations: ["Kannur"],
  // },
  // {
  //     name: "Royal School of English",
  //     logo: ieltsMaster,
  //     features: [
  //         "IELTS Master Student Portal.",
  //         "Personal Mentor Support.",
  //         "Practices and Detailed Feedback."
  //     ],
  //     batches: [
  //         {
  //             name: "1 Month Batch",
  //             usualFee: 7000,
  //             viaUs: 6500,
  //             off: 500,
  //             type: "Offline",
  //             timings: ["10:00 AM - 04:00 AM"],
  //         },
  //         {
  //             name: "20 Days Crash Course",
  //             usualFee: 6500,
  //             viaUs: 6000,
  //             off: 500,
  //             type: "Online",
  //             timings: ["09:00 AM - 01:00 AM", "01:00 PM - 05:00 PM"],
  //         },
  //     ],
  //     locations: ["Kottayam"],
  // },
  // {
  //     name: "Britfort Academy",
  //     logo: ieltsMaster,
  //     features: [
  //         "Individual attention.",
  //         "Free exam preparation classes.",
  //         "Free study materials & Hand book."
  //     ],
  //     batches: [
  //         {
  //             name: "1 Month Batch",
  //             usualFee: 7000,
  //             viaUs: 6500,
  //             off: 500,
  //             type: "Offline",
  //             timings: ["10:00 AM - 04:00 AM"],
  //         },
  //         {
  //             name: "20 Days Crash Course",
  //             usualFee: 6500,
  //             viaUs: 6000,
  //             off: 500,
  //             type: "Online",
  //             timings: ["09:00 AM - 01:00 AM", "01:00 PM - 05:00 PM"],
  //         },
  //     ],
  //     locations: ["Kottayam"],
  // },
];
