import "./about.css";
import AboutImage from "../../assets/images/aboutImage.png";
import About1 from "../../assets/images/about1.png";
import About2 from "../../assets/images/about2.png";
import About3 from "../../assets/images/about3.png";
import About4 from "../../assets/images/about4.png";
import About5 from "../../assets/images/about5.png";
import About6 from "../../assets/images/about6.png";
import About7 from "../../assets/images/about7.png"

const About = () => {
  return (
    <>
      <div className="about_text_container">
        <div className="about_text_header">
          Heyy<span style={{ color: "yellow" }}>🖐️</span>
        </div>

        <div className="about_desc_text">
          We are set to host Kerala's largest Al Summit for students, designed
          to equip aspiring students with cutting-edge knowledge and real-world
          experience in the Al domain. Attendees will earn three valuable
          certifications by completing one internship and participating in two
          immersive workshops. This milestone event will offer students a rare
          opportunity to: Engage directly with industry experts Gain practical,
          hands-on experience Expand their network with fellow students,Al
          professionals, and industry leaders.
        </div>

        <div className="about_img_container">
          <img src={AboutImage} alt="about_img" className="about_img" />
        </div>

        <div className="about_img_small_screen_container">
          <img
            src={About1}
            alt="about_img"
            className="about_img_small_screen_img aboutWidth1"
          />
          <img
            src={About2}
            alt="about_img"
            className="about_img_small_screen_img aboutWidth2"
          />
          <img
            src={About3}
            alt="about_img"
            className="about_img_small_screen_img aboutWidth3"
          />
          <img
            src={About4}
            alt="about_img"
            className="about_img_small_screen_img aboutWidth4"
          />
          <img
            src={About5}
            alt="about_img"
            className="about_img_small_screen_img aboutWidth5"
          />
          <div className="college_container">
            <img
              src={About6}
              alt="about_img"
              className="about_img_small_screen_img aboutWidth6"
            />

            <div className="college_container_text">
              <img src={About7} alt="about_text" className="college_text_img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
