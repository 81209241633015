import "./workShop.css";
import Virtual from "../../assets/images/Virtual Reality 2.png";
import Coding from "../../assets/images/Coding 1.png";
import TeamCoding from "../../assets/images/Team Coding 2.png";
import Welcome from "../../assets/images/welcome.png";
import welcomeSmall from "../../assets/images/workImgSmall.png";

const WorkShop = () => {
  return (
    <>
      <div className="welcome_kerala_container">
        <img
          src={Welcome}
          alt="welcome_kerala_img"
          className="welcome_kerala_img"
        />

        <img
          src={welcomeSmall}
          alt="welcome_kerala_img"
          className="welcome_kerala_small_img"
        />
      </div>

      <div className="workshop_container">
        <div className="workshop">
          <div className="workshopImage_container">
            <img src={Virtual} alt="workshopImage" className="workshopImage" />
          </div>
          <div className="workshop_desc">
            <div className="work_main_header">WORKSHOP 1</div>
            <div className="work_header">Google Al Studio</div>
            <div className="work_text">
              An in-depth session exploring Google's Al tools, platforms,
              <br />
              and technologies. This workshop will equip students with
              <br />
              hands-on experience in building Al models using Google's
              <br />
              cutting-edge technologies.
            </div>

            <div className="work_text_small">
              An in-depth session exploring Google's Al tools, platforms, and
              technologies. This workshop will equip students with hands-on
              experience in building Al models using Google's cutting-edge
              technologies.
            </div>
          </div>
        </div>

        <div className="workshop">
          <div className="workshop_desc work work_desc_large">
            <div className="work_main_header">WORKSHOP 2</div>
            <div className="work_header">
              Prompt Engineering &<br />
              Data Management
            </div>
            <div className="work_text">
              A workshop focusing on the art of crafting effective
              <br /> prompts for Al models, a crucial skill in building
              <br /> conversational and functional Al solutions.
            </div>
          </div>
          <div className="workshopImage_container">
            <img src={Coding} alt="workshopImage" className="workshopImage" />
          </div>

          <div className="workshop_small_desc">
            <div className="work_main_header">WORKSHOP 2</div>
            <div className="work_header">
              Prompt Engineering &<br />
              Data Management
            </div>
            <div className="work_text_small">
              A workshop focusing on the art of crafting effective prompts for
              Al models, a crucial skill in building conversational and
              functional Al solutions.
            </div>
          </div>
        </div>

        <div className="workshop">
          <div className="workshopImage_container">
            <img
              src={TeamCoding}
              alt="workshopImage"
              className="workshopImage"
            />
          </div>
          <div className="workshop_desc">
            <div className="work_main_header">INTERNSHIP</div>
            <div className="work_header">Building a Basic Al Project</div>
            <div className="work_text">
              This 5-day internship is designed to help students from all
              disciplines
              <br /> create their first Al model from scratch. Whether it's a
              beginner or
              <br /> someone looking to strengthen their foundational knowledge,
              this
              <br /> program will guide you through the essential steps of
              building basic Al
              <br /> projects. Through hands-on learning, you'll explore the
              fundamentals of
              <br /> Al, including data preparation, model building, and
              evaluation. By the
              <br /> end of the internship, you will be gaining valuable
              information that can
              <br /> be applied to future Al endeavours.
            </div>

            <div className="work_text_small">
              This 5-day internship is designed to help students from all
              disciplines create their first Al model from scratch. Whether it's
              a beginner or someone looking to strengthen their foundational
              knowledge, this program will guide you through the essential steps
              of building basic Al projects. Through hands-on learning, you'll
              explore the fundamentals of Al, including data preparation, model
              building, and evaluation. By the end of the internship, you will
              be gaining valuable information that can be applied to future Al
              endeavours.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkShop;
