import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import aboutUsExtraContentImg from '../assets/about-us-extra-content-img.svg';
import instagramIcon from '../assets/instagram.svg';
import eyeIcon from '../assets/eye.svg';
import whatsappIcon from '../assets/whatsapp.svg';
import handShakeIcon from '../assets/hand-shake.svg';
import mixpanel from 'mixpanel-browser';

const About = () => {

    useEffect(() => {
        mixpanel.track('Website - About')
    }, [])

    const stats = [
        {
            title: "38K",
            text: "Community Members on Instagram",
            icon: instagramIcon,
        },
        {
            title: "4.6 Million+",
            text: "Aspirants reached in last 90 days",
            icon: eyeIcon,
        },
        {
            title: "30K+",
            text: "Members in our WhatsApp community",
            icon: whatsappIcon,
        },
        {
            title: "50+",
            text: "Partners",
            icon: handShakeIcon,
        }
    ];

    return (
        <section className='about-us'>
            <div className="about-title">
                About us
            </div>
            <div className="about-content">
                Being Abroad is a successful startup registered with Kerala Startup Mission & Recognized by Startup India, headquartered in Kochi. In the past two years, we've assisted over 17,000 students in achieving their dreams of studying abroad by clearing their Doubts We've grown into the largest community of study abroad aspirants in Kerala, with more than 70,000 community members. With 50+ collaborations, our goal is to help even more students connect with reliable educational consultants.. We offer comprehensive support for students planning to study abroad, including clarifying their doubts, connecting them with the best IELTS, PTE, and German coaching institutes, trusted consultancy services, and even assistance with connecting education loans providers. We've got all your study abroad needs covered.
                <br />
                Do join our community and be part of Kerala's largest Community of Study Abroad aspirants.
            </div>
            <div className="extra-content">
                <div className="extra-content-text">
                    We are launching an exclusive India's first one of a kind app named "Being Abroad" where all the study abroad aspirants could watch curated contents, immerge in conversations, network with others , Take Admission for IELTS/PTE/German classes in the best Institutes , clear doubts with our AI guru etc.
                </div>
                <img src={aboutUsExtraContentImg} alt="about-us-extra-content-img" className="extra-content-img" />
            </div>
            <div className="about-stats">
                {stats.map((stat => (
                    <div className="stat">
                        <img src={stat.icon} alt="" className="stat-logo" />
                        <div className="stat-content">
                            <div className="stat-title">
                                {stat.title}
                            </div>
                            <div className="stat-text">
                                {stat.text}
                            </div>
                        </div>
                    </div>
                )))}
            </div>
            <div className="home-navigation" >
                <a href='/'><FontAwesomeIcon icon={faAngleLeft} className='home-navigation-icon' /></a>
                <div className="home-navigation-text">
                    Home
                </div>
            </div>
        </section>
    )
}

export default About;
