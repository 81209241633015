import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import instagramLogo from "../assets/instagram-logo.png";
import youtubeLogo from "../assets/youtube-logo.png";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-end">
                    <span className="footer-text">Created with <FontAwesomeIcon icon={faHeart} color='#F35166' /> by team Being Abroad</span>
                    <span className="social-logos">
                        <a href='https://instagram.com/thebeingabroad?igshid=NTc4MTIwNjQ2YQ==' target="_blank" rel="noreferrer"><img src={instagramLogo} alt="instagram-logo" className="social-logo" /></a>
                        <a href='https://youtube.com/@beingabroad' target="_blank" rel="noreferrer"><img src={youtubeLogo} alt="youtubeLogo" className="social-logo" /></a>
                    </span>
                    <div className="tc-policy">
                        <div className='policy-link-container'>
                            <span className="footer-text"><a className='policy-link' href='terms-and-conditions'>Terms &amp; Conditions</a></span>
                        </div>
                        <div className='policy-link-container'>
                            <a className='policy-link' href='privacy-policy'>
                                <span className="footer-text">Privacy Policy</span></a>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer-mobile">
                <div className="footer-end">
                    <span className="footer-text border-bottom">Created with <FontAwesomeIcon icon={faHeart} color='#F35166' /> by team Being Abroad</span>
                    <div className="footer-bottom-mobile">
                        <div className="social-logos">
                            <a href='https://instagram.com/thebeingabroad?igshid=NTc4MTIwNjQ2YQ==' target="_blank" rel="noreferrer"><img src={instagramLogo} alt="instagram-logo" className="social-logo" /></a>
                            <a href='https://youtube.com/@beingabroad' target="_blank" rel="noreferrer"><img src={youtubeLogo} alt="youtubeLogo" className="social-logo" /></a>
                        </div>
                        <div className="tc-policy">
                            <span className="policy-text"><a className='policy-link' href='terms-and-conditions'>Terms &amp; Conditions</a></span>
                            <span className="policy-text"><a className='policy-link' href='privacy-policy'>Privacy Policy</a></span>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
