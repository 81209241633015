import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { faStar } from '@fortawesome/free-regular-svg-icons';
import Navbar from '../components/Navbar';
import earth from '../assets/earth.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAnalyticsEventTracker from '../utils/useAnalyticsEventTracker';
import { useLocation } from 'react-router-dom';
import timer from '../assets/timer.png';

const RegisterForm = () => {

    const navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('Register');
    const { state } = useLocation();
    const { institute, batch } = state;

    const timings = batch.timings;

    const [error, setError] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [mail, setMail] = useState("");
    const [currentlyDoing, setCurrentlyDoing] = useState("");
    const [offerSource, setOfferSource] = useState("");
    const [timing, setTiming] = useState("");

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const saveUserDetails = async () => {
        if (
            name !== "" &&
            number !== "" &&
            mail !== "" && String(mail)
                .toLowerCase()
                .match(
                    regex
                ) &&
            (number.length === 10 || number.length === 12) &&
            currentlyDoing !== "" &&
            offerSource !== "" &&
            (Array.isArray(timings) ? timing !== "" : true)
        ) {
            setError("");
            const request = {
                name: name,
                number: number,
                mail: mail,
                currentlyDoing: currentlyDoing,
                offerSource: offerSource,
                institution: institute.name,
                batch: batch.name,
                type: batch.type,
                timing: timing,
            };
            await axios.post("https://www.thebeingabroad.com/api/v1/auth/addData", request)
                .then(res => {
                    if (res.status === 200) {
                        navigate("/register-contact")
                    }
                })
                .then((res) => {
                    gaEventTracker('registered');
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            setError("Hi, Kindly fill all the fields before clicking Submit!");
            setTimeout(() => setError(""), 5400);
        }
    }

    return (
        <section className='register-form-screen'>
            <Navbar />
            <img className='earth-img' src={earth} alt='' />
            <div className="form-main-container">

                <div className="title-card form-title-card">
                    <div className='institution-details'>
                        <div className="title-container">
                            <div className="partner-logo">
                                <img src={institute.logo} alt="institution-logo" className="institution-logo" />
                            </div>
                            <span className="title-text">
                                {institute.name}
                            </span>
                        </div>
                        <div className="features">
                            {institute.features.map(feature => (
                                <div className="feature-text" key={Math.random()}>
                                    <FontAwesomeIcon icon={faStar} /> {feature}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="timings">
                        {Array.isArray(timings) && timings.length > 0 && <>
                            <span className='feature-text'>
                                You can choose a comfortable <br />
                                batch of your choice from here 👇.
                            </span>
                            <span>
                                <img src={timer} className='timer-img' alt='' />
                            </span>
                        </>}
                        {(!Array.isArray(timings) && timings.length > 0) && <span className='feature-text'>
                            {/* You can choose the class time  according to <br />
                            the availability after joining in the Institute. */}
                            {timings}
                        </span>}
                    </div>
                    <div className="timing-text-container">
                        {Array.isArray(timings) && timings.length > 0 && timings.map((time) =>
                            <div key={Math.random()} className={time === timing ? "batch-timing batch-timing-selected" : "batch-timing"} onClick={() => setTiming(time)}>
                                <span className="batch-timing-text">
                                    {time}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-container">
                    <div className="form-content">
                        <div className="name-container">
                            <input value={name} type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)} className="form-input name-input" />
                            <input value={number} type="number" placeholder="Phone Number" onChange={(e) => setNumber(e.target.value)} className={(number.length === 10 || number.length === 12 || number.length < 1) ? "form-input number-input" : "form-input number-input number-input-error"} />
                        </div>
                        <input value={mail} type="email" placeholder="Mail" onChange={(e) => setMail(e.target.value)} className={mail.length < 1 || String(mail).toLowerCase().match(regex) ? "form-input email-input" : "form-input email-input number-input-error"} />
                        <select value={currentlyDoing} onChange={(e) => setCurrentlyDoing(e.target.value)} className="form-select current-position-input">
                            <option value="">What are you currently doing?</option>
                            <option value="Completed Studies">I've Completed Studies</option>
                            <option value="Working Now">Working Now</option>
                            <option value="Student">I'm a Student</option>
                        </select>
                        <select value={offerSource} onChange={(e) => setOfferSource(e.target.value)} className="form-select knowledge-input">
                            <option value="">How did you know about the offer?</option>
                            <option value="May">Being Abroad Insta Page</option>
                            <option value="June">Friend Suggested</option>
                            <option value="July">Saw Ads</option>
                            <option value="Not decided">Other</option>
                        </select>

                        <button className="submit-button" onClick={() => saveUserDetails()}>
                            Submit
                        </button>

                    </div>
                </div>

            </div>

            <div className="toast-container">
                <div className={error === "" ? "error-toast" : "error-toast show"}>{error}</div>
            </div>

        </section>
    )
}

export default RegisterForm;
