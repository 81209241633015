import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from "@fortawesome/free-brands-svg-icons"

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import contactVector from "../assets/contact-vector-desktop.webp";
import contactVectorMobile from "../assets/contact-vector-mobile.png";
import mixpanel from 'mixpanel-browser';

const Contact = () => {

    useEffect(() => {
        mixpanel.track('Website - Contact')
    }, [])
    
    return (
        <section className="contact-screen">
            <Navbar color={"#3D3D5C"} />

            <img src={contactVector} alt="contact-vector" className="contact-vector" />
            <img src={contactVectorMobile} alt="contact-vector" className="contact-vector-mobile" />

            <div className="contact-details">
                <div className="contact-title">
                    Hi,
                </div>
                <div className="contact-subtitle">
                    To contact us, mail us at
                    <div className="contact">
                        thebeingabroad@gmail.com <FontAwesomeIcon icon={faCopy} />
                    </div>
                </div>
                <div className="contact-subtitle">
                    or Dm us in Instagram
                    <div className="contact">
                        @thebeingabroad <FontAwesomeIcon icon={faInstagram} />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Contact;
