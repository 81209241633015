import "./benefit.css";
import BenefitImg from "../../assets/images/benefit.png";
import BenefitSmall from "../../assets/images/benefits_small.png";

const Benefit = () => {
  return (
    <>
      <div className="benefits_container">
        <div className="benefit_item_container">
          <div className="benefit_header">STUDENT BENEFITS</div>
          <div className="benefit_items_container">
            <div className="benefit_item">
              <div className="inner_benefit_item">3 CERTIFICATES</div>
            </div>

            <div className="benefit_item">
              <div className="inner_benefit_item">LEARN AI</div>
            </div>
          </div>
          <div className="benefit_items_container">
            <div className="benefit_item">
              <div className="inner_benefit_item">ATTEND SUMMIT</div>
            </div>

            <div className="benefit_item">
              <div className="inner_benefit_item">GET REGULAR AI UPDATES</div>
            </div>
          </div>
          <div>
            <div className="benefit_item">
              <div className="inner_benefit_item">
                50 INTERNSHIPS WITH MINIMUM 10K STIPEND FOR BEST PERFORMING
                STUDENTS
              </div>
            </div>
          </div>
        </div>
        <div className="benefit_img_container">
          <img src={BenefitImg} alt="benefit_img" className="benefit_img" />
        </div>
      </div>

      <div className="benefit_small_screen_container">
        <div className="benefit_small_img_container">
          <img
            src={BenefitSmall}
            alt="benefit_img"
            className="benefit_small_img"
          />
        </div>
      </div>
    </>
  );
};

export default Benefit;
