import "./program.css";
import ProgramTop from "../../assets/images/prog_top.png";
import ProgramBottom from "../../assets/images/prog_bottom.png";

const Program = () => {
  return (
    <div className="program_container">
      <img src={ProgramTop} alt="prog_img" className="program_top_img" />

      <div className="program_schedule_container">
        <div className="program_schedule_header">PROGRAM SCHEDULE</div>

        <div className="prog_time_container">
          <div className="prog_date_container">
            <div className="prog_date_text">
              <div className="prog_date_num_text">19</div>
              <div className="prog_day_text">
                OCTOBER
                <br />
                SATURDAY
              </div>
            </div>
            <div className="prog_item">SUMMIT DAY</div>
          </div>

          <div className="prog_item_schedule_container">
            <div className="prog_item_schedule_top">
              <div className="prag_time">08.30 AM-10.00 AM</div>
              <div className="prog_single">SPOT REGISTRATION STARTS</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">10.00 AM-10.05 AM</div>
              <div className="prog_single">INAUGURATION STARTS</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">10.05 AM-10.25 AM</div>
              <div className="prog_single">WELCOME SPEECH</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">10.25 AM-10.30 AM</div>
              <div className="prog_single">ACCELERATE-X INTRO</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">10.30 AM-10.45 AM</div>
              <div className="prog_single">ACCELERATE-X LAUNCH</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">10.45 AM-11.00 AM</div>
              <div className="prog_single">KEYNOTE ADDRESS</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">11.00 AM-11.30 AM</div>
              <div className="prog_single">
                PANEL DISCUSSION 1<br />
                WHY IS PEOPLE INVESTING IN AI &<br /> HOW IS IT CREATING JOBS?
              </div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">11.30 AM-11.45 AM</div>
              <div className="prog_single">
                INTRO SESSION BY GOOGLE TEAM
              </div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">11.45 AM-12.15 PM</div>
              <div className="prog_single">
                PANEL DISCUSSION 2<br />
                WHAT ARE NEW AGE HR LOOKING IN STUDENTS?
              </div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time"> 12.15 PM-12.30 PM</div>
              <div className="prog_single">THANKYOU SPEECH</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">12.30 PM-01.30 PM</div>
              <div className="prog_single">LUNCH</div>
            </div>

            <div className="prog_item_schedule_top">
              <div className="prag_time">01.30 PM-05.00 PM</div>
              <div className="prog_single">
                GOOGLE WORKSHOP &<br /> INTERNSHIP CLASS DAY 1 (3.5 HOURS)
              </div>
            </div>
          </div>
        </div>

        <div className="online_container">
          <div className="inner_online_container">ONLINE</div>
        </div>

        <div className="online_schedule_container">
          <div className="online_schedule_items_container">
            <div className="prog_date_container">
              <div className="prog_date_text">
                <div className="prog_date_num_text">20</div>
                <div className="prog_day_text">
                  OCTOBER
                  <br />
                  SUNDAY
                </div>
              </div>
              <div className="prog_item prog_item_large">
                WORKSHOP 2 &<br /> INTERNSHIP DAY 2
              </div>

              <div className="prog_item_small">
                WORKSHOP 2 & INTERNSHIP DAY 2
              </div>
            </div>

            <div className="online_shedule_text">
              <div className="prog_item_schedule">
                <div className="prag_time">11.00 AM-01.00 PM</div>
                <div className="progr_item">WORKSHOP 2 ( 2 HOURS )</div>
              </div>

              <div className="prog_item_schedule">
                <div className="prag_time">07.00 PM-09.00 PM</div>
                <div className="progr_item">INTERNSHIP DAY 2 ( 2 HOURS )</div>
              </div>
            </div>
          </div>

          <div className="online_schedule_items_container">
            <div className="prog_date_container">
              <div className="prog_date_text">
                <div className="prog_date_num_text">21</div>
                <div className="prog_day_text">
                  OCTOBER
                  <br />
                  MONDAY
                </div>
              </div>
              <div className="prog_item">INTERNSHIP DAY 3</div>
            </div>

            <div className="online_shedule_text">
              <div className="prog_item_schedule">
                <div className="prag_time">07.00 PM-09.00 PM</div>
                <div>( 2 HOURS )</div>
              </div>
            </div>
          </div>

          <div className="online_schedule_items_container">
            <div className="prog_date_container">
              <div className="prog_date_text">
                <div className="prog_date_num_text">22</div>
                <div className="prog_day_text">
                  OCTOBER
                  <br />
                  TUESDAY
                </div>
              </div>
              <div className="prog_item">INTERNSHIP DAY 4</div>
            </div>

            <div className="online_shedule_text">
              <div className="prog_item_schedule">
                <div className="prag_time">07.00 PM-09.00 PM</div>
                <div>( 2 HOURS ) </div>
              </div>
            </div>
          </div>

          <div className="online_schedule_items_container">
            <div className="prog_date_container">
              <div className="prog_date_text">
                <div className="prog_date_num_text">23</div>
                <div className="prog_day_text">
                  OCTOBER
                  <br />
                  WEDNESDAY
                </div>
              </div>
              <div className="prog_item">INTERNSHIP DAY 5</div>
            </div>

            <div className="online_shedule_text">
              <div className="prog_item_schedule">
                <div className="prag_time">07.00 PM-09.00 PM</div>
                <div>( 2 HOURS )</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={ProgramBottom} alt="prog_img" className="program_bottom_img" />
    </div>
  );
};

export default Program;
